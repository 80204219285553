<template>
  <bs-popup id="master-kurs-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
    <template v-slot:title>
      Master Kurs
    </template>
    <template v-slot:content>
      <bs-error-message :errors="errorMessages" />

      <div class="mb-3">
        <label for="startDate" class="form-label">Tanggal Awal Berlaku</label>
        <bs-date-picker v-model="model.startDate"/>
        <bs-input-error :errors="errorMessages" keyError="Tanggal Awal Berlaku" />
      </div>
      <div class="mb-3">
        <label for="endDate" class="form-label">Tanggal Akhir Berlaku</label>
        <bs-date-picker v-model="model.endDate" />
        <bs-input-error :errors="errorMessages" keyError="Tanggal Akhir Berlaku" />
      </div>
      <div class="mb-3">
        <label for="currencyFromCode" class="form-label">Mata Uang Asal</label>
        <currency-combo v-model="model.currencyFromId" :textDefault="model.currencyFromCode"/>
        <bs-input-error :errors="errorMessages" keyError="Mata Uang Asal" />
      </div>
      <div class="mb-3">
        <label for="currencyToCode" class="form-label">Mata Uang Tujuan</label>
        <currency-combo v-model="model.currencyToId" :textDefault="model.currencyToCode"/>
        <bs-input-error :errors="errorMessages" keyError="Mata Uang Tujuan" />
      </div>
      <div class="mb-3">
        <label for="conversionRate" class="form-label">Nilai Kurs</label>
        <bs-textbox v-model="model.conversionRate" />
        <bs-input-error :errors="errorMessages" keyError="Nilai Kurs" />
      </div>
      <div class="mb-3">
        <label for="supplierName" class="form-label">Suplier</label>
        <vendor-combo v-model="model.supplierId" :textDefault="model.supplierName"/>
        <bs-input-error :errors="errorMessages" keyError="Suplier" />
      </div>
      <div class="mb-3">
        <label for="locationCode" class="form-label">Lokasi</label>
        <location-combo v-model="model.locationId" :textDefault="model.locationCode" />
        <bs-input-error :errors="errorMessages" keyError="Lokasi" />
      </div>
      <div class="mb-3">
        <label for="source" class="form-label">Sumber</label>
        <bs-textbox v-model="model.source" />
        <bs-input-error :errors="errorMessages" keyError="Sumber" />
      </div>

    </template>
    <template v-slot:footer>
      <button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
      <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
    </template>
  </bs-popup>
</template>

<script>
import MasterKursService from "@/services/master-kurs-service.js";
import BsDatePicker from "@/plugins/components/BsDatePicker.vue";
import CurrencyCombo from "@/components/CurrencyCombo.vue";
import LocationCombo from "@/components/LocationCombo.vue";

export default {
  components: {
    LocationCombo,
    CurrencyCombo,
    BsDatePicker

  },
  data: function () {
    return {
      id: 0,
      model: MasterKursService.default(),
      errorMessages: null
    }
  },
  async mounted() {
    let id = this.$route.params.id;
    if (id !== undefined && id !== null && id !== 0) {
      this.id = id;
      await this.loadData();
    }
  },
  methods: {
    async loadData() {
      if (this.id === undefined || this.id === null || this.id === 0) {
        return;
      }

      const result = await MasterKursService.getObject(this.id);
      if (result.status) {
        this.errorMessages = result.errorMessages;
      }
      else {
        this.errorMessages = null;
      }
      this.model = result.data;
    },
    async onPopupClosed() {
      this.$router.push('/master-kurs');
    },
    async close() {
      this.$refs.form.closePopup();
    },
    async saveData() {
      this.formValidation();

      if(this.errorMessages != null) return;

      this.$refs.form.closePopup();
      try {
        this.$store.commit('progressCounterQueueIncrement');

        let status = false;
        if (this.id !== undefined && this.id !== null && this.id !== 0) {
          const result = await MasterKursService.update(this.model, this.id);
          status = result.status;
          if (status) {
            this.errorMessages = null;
          }
          else {
            this.errorMessages = result.errorMessages;
          }
        }
        else {
          const result = await MasterKursService.create(this.model);
          status = result.status;

          if (status) {
            this.errorMessages = null;
            this.id = result.data.id;
          }
          else {
            this.errorMessages = result.errorMessages;
          }
        }

        if (!status) return;

        if(this.$refs.form != null) this.$refs.form.closePopup();
        this.$router.push(`/master-kurs/detail/${this.id}`);
      }
      finally {
        this.$store.commit('progressCounterQueueDecrement');
      }
    },
    formValidation() {
      this.errorMessages = null;

      if (this.model.startDate == null || this.model.startDate == '') {
        if (this.errorMessages == null) this.errorMessages = {};
        this.errorMessages['Tanggal Awal Berlaku'] = ['Tanggal Awal Berlaku harus diisi.'];
      }

      if (this.model.endDate == null || this.model.endDate == '') {
        if (this.errorMessages == null) this.errorMessages = {};
        this.errorMessages['Tanggal Akhir Berlaku'] = ['Tanggal Akhir Berlaku harus diisi.'];
      }

      if (this.model.startDate != null && this.model.endDate != null && this.model.startDate > this.model.endDate) {
        if (this.errorMessages == null) this.errorMessages = {};
        this.errorMessages['Tanggal Akhir Berlaku'] = ['Tanggal Akhir Berlaku harus lebih atau sama dengan Tanggal Awal Berlaku.'];
      }

      if (this.model.currencyFromId == null || this.model.currencyFromId == 0) {
        if (this.errorMessages == null) this.errorMessages = {};
        this.errorMessages['Mata Uang Asal'] = ['Mata Uang Asal harus diisi.'];
      }

      if (this.model.currencyToId == null || this.model.currencyToId == 0) {
        if (this.errorMessages == null) this.errorMessages = {};
        this.errorMessages['Mata Uang Tujuan'] = ['Mata Uang Tujuan harus diisi.'];
      }

      if (this.model.conversionRate == null || this.model.conversionRate == '') {
        if (this.errorMessages == null) this.errorMessages = {};
        this.errorMessages['Nilai Kurs'] = ['Nilai Kurs harus diisi.'];
      }

      if (isNaN(this.model.conversionRate)) {
        if (this.errorMessages == null) this.errorMessages = {};
        if (this.errorMessages['Nilai Kurs'] != null) {
          this.errorMessages['Nilai Kurs'].push('Nilai Kurs harus berupa angka.');
        } else {
          this.errorMessages['Nilai Kurs'] = ['Nilai Kurs harus berupa angka.'];
        }
      }
    }
  }
}
</script>