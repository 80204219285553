import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async getList(filter)
    {
        const response = await httpService.get(`kurs`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status)
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++)
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`kurs/${id}`);

        if (!response.status) {
            response.data = this.default();
            return response;
        }

        let data = response.data
        response.data = this._toObjectForEdit(data);
        return response;
    },

    async create(model) {
        model.locationId = model.locationId == null ? 0 : model.locationId;
        model.supplierId = model.supplierId == null ? 0 : model.supplierId;
        model.conversionRate = parseFloat(model.conversionRate) ? parseFloat(model.conversionRate) : null;
        const response = await httpService.post('kurs', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async update(model, id) {
        model.locationId = model.locationId == null ? 0 : model.locationId;
        model.supplierId = model.supplierId == null ? 0 : model.supplierId;
        model.conversionRate = parseFloat(model.conversionRate) ? parseFloat(model.conversionRate) : null;
        const response = await httpService.update('kurs', id, model);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async delete(id) {
        const response = await httpService.delete('kurs', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    default() {
        return {
            id: 0,
            startDate: null,
            endDate: null,
            currencyFromId: null,
            currencyToId: null,
            conversionRate: null,
            supplierId: null,
            locationId: null,
            source: null,
        };
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.startDate = jsonData.startDate;
        result.endDate = jsonData.endDate;
        result.currencyFromId = jsonData.currencyFromId;
        result.currencyFromCode = jsonData.currencyFromCode;
        result.currencyToCode = jsonData.currencyToCode;
        result.currencyToId = jsonData.currencyToId;
        result.conversionRate = jsonData.conversionRate;
        result.supplierId = jsonData.supplierId;
        result.supplierCode = jsonData.supplierCode;
        result.supplierName = jsonData.supplierName;
        result.locationId = jsonData.locationId;
        result.locationCode = jsonData.locationCode;
        result.locationName = jsonData.locationName;
        result.source = jsonData.source;
        return result;
    },

    _toObjectForEdit(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.startDate = new Date(jsonData.startDate);
        result.endDate = new Date(jsonData.endDate);
        result.currencyFromId = jsonData.currencyFromId;
        result.currencyFromCode = jsonData.currencyFromCode;
        result.currencyToCode = jsonData.currencyToCode;
        result.currencyToId = jsonData.currencyToId;
        result.conversionRate = jsonData.conversionRate;
        result.supplierId = jsonData.supplierId;
        result.supplierCode = jsonData.supplierCode;
        result.supplierName = jsonData.supplierName;
        result.locationId = jsonData.locationId;
        result.locationCode = jsonData.locationCode;
        result.locationName = jsonData.locationName;
        result.source = jsonData.source;
        return result;
    }
});